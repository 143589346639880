import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Container from '../components/Container';
import Intro from '../components/Intro';
import { P, Headline, Highlight } from '../components/Typography';
import ContactTeaser from '../components/ContactTeaser';
import Slider from '../components/Slider';
import Button from '../components/Button';

import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';
import { getImageFromNode } from '../helpers/utils';

// Icons
import iconAircon from '../images/loxone/icon-aircon.svg';
import iconEmob from '../images/loxone/icon-emob.svg';
import iconLightning from '../images/loxone/icon-lightning.svg';
import iconSecurity from '../images/loxone/icon-security.svg';
import iconShading from '../images/loxone/icon-shading.svg';

/** Bild im Rechts-Links-Wechsel */
const Img = styled(GatsbyImage)`
    width: 100%;
`;

/** Angepasster Slider */
const StyledSlider = styled(Slider)`
    background-color: ${colors.primary};
    border-radius: 1rem;
    overflow: hidden;

    ${mq.medium`
        margin-left: 0;
        margin-right: 0;
        max-width: none;
        width: auto;
    `};
`;

/** Element next to slider */
const NextToSlider = styled.div`
    /* width: 50%; */
`;

/** SliderItems  */
const IconSliderItem = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.white};
    align-items: center;
    text-align: center;
    padding: ${rem(25)};
    width: 100%;

    ${mq.large`
        padding: ${rem(40)};
    `};

    > strong {
        margin-top: ${rem(20)};
    }

    > img {
        max-width: ${rem(250)};
    }
`;

const LoxonePage = ({ data }) => {
    const { stageImage, contentImages } = data;
    return (
        <Layout title="Loxone">
            <Stage image={stageImage.childImageSharp} title="Loxone" />
            <Section bg="pattern">
                <Container size="l" grid cols="2" reverseOnMobile gap="xxl">
                    <Intro headline="50.000 Handgriffe weniger, mehr Zeit zum Leben.">
                        <P>
                            Ein Loxone Smart Home erledigt die meisten Aufgaben rund um Sicherheit,
                            Komfort und Energie-Effizienz von selbst. Das spart jährlich mehr als
                            50.000 Handgriffe und gibt Ihnen das einzig Unwiederbringliche zurück:
                            Zeit – für alles, was Sie nicht aus Gewohnheit erledigen, sondern mit
                            Begeisterung.
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'loxone/contentimage-intro.jpg').node
                                .childImageSharp.fluid
                        }
                    />
                </Container>
                <Container size="l" grid cols="2">
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'loxone/contentimage-impressionen.jpg')
                                .node.childImageSharp.fluid
                        }
                    />
                    <div>
                        <Headline level="h2">
                            Gebäudeautomation
                            <br />
                            (Smart Home)
                        </Headline>
                        <P>
                            Das Thema Gebäudeautomation (Smart Home) ist gefragter denn je. Im Smart
                            House von Loxone spielen alle Komponenten perfekt zusammen. Ihr
                            intelligentes Haus bringt Beschattung, Heizung und Licht perfekt in
                            Einklang. Das Zuhause passt sich ihren Bedürfnissen an und ermöglicht
                            eine Vielzahl von Einsatzmöglichkeiten (Lichtsteuerung, Rollladen- und
                            Jalousiesteuerung, Heizungssteuerung, Multiroom Audio, Einbruchschutz,
                            Zutrittkontrolle, etc.)
                        </P>
                        <P>
                            Aktuell sind wir als Silber Partner bei der Firma Loxone gelistet. Gerne
                            kümmern wir uns auch um ihr Projekt
                        </P>
                        <Button to="https://www.loxone.com/dede/" target="_blank">
                            Mehr über Loxone erfahren
                        </Button>
                    </div>
                </Container>
            </Section>
            <Section bg="gray" container="l">
                <Headline level="h2" gap="l">
                    Was kann man mit Loxone alles steuern?
                </Headline>
                <Container grid cols="2">
                    <StyledSlider
                        hideCounter
                        specialArrowStyles={css`
                            background-color: transparent;
                            border-radius: 0;
                            box-shadow: none;
                            transform: translate(0, -50%) !important;
                        `}
                    >
                        <IconSliderItem key="loxone-licht">
                            <img src={iconLightning} alt="Icon Licht" />
                            <Highlight textColor="white">Licht</Highlight>
                            <P>
                                Mit der intelligenten Lichtsteuerung sorgt Ihr Smart Home zur
                                richtigen Zeit für die richtige Atmosphäre. Passen Sie Ihre
                                Lichtstimmungen jederzeit und ganz individuell an.
                            </P>
                        </IconSliderItem>
                        <IconSliderItem key="loxone-shading">
                            <img src={iconShading} alt="Icon Beschattung" />
                            <Highlight textColor="white">Beschattung</Highlight>
                            <P>
                                Jalousie, Rolladen, Markise oder andere Beschattungstypen können
                                perfekt mit Loxone angesteuert werden und sorgen so für Hitzeschutz
                                im Sommer und Wärme im Winter.
                            </P>
                        </IconSliderItem>
                        <IconSliderItem key="loxone-klima">
                            <img src={iconAircon} alt="Icon Klima" />
                            <Highlight textColor="white">Klima</Highlight>
                            <P>
                                Regeln Sie die Temperaturen in Ihrem Eigenheim pro Raum. Im
                                perfekten Zusammenspiel mit der Beschattung wird die Sonnenenergie
                                zum Aufwärmen der Räume genutzt.
                            </P>
                        </IconSliderItem>
                        <IconSliderItem key="loxone-security">
                            <img src={iconSecurity} alt="Icon Sicherheit" />
                            <Highlight textColor="white">Sicherheit</Highlight>
                            <P>
                                Im Loxone Smart Home hat Sicherheit viele Facetten: Es schlägt
                                Einbrecher in die Flucht, beschützt Sie und Ihre Liebsten vor
                                Gefahren wie Feuer oder Wasser, bewacht das Gebäude und hält Ihre
                                Daten privat.
                            </P>
                        </IconSliderItem>
                        <IconSliderItem key="loxone-emob">
                            <img src={iconEmob} alt="Icon Glühbirne" />
                            <Highlight textColor="white">Elektromobilität</Highlight>
                            <P>
                                Mit dem E-Mobility Starter Kit können Sie die Ladestation perfekt
                                ins Loxone Smart Home integrieren! Profitieren Sie von der
                                Sonnenenergie und tanken Sie zum Nulltarif!
                            </P>
                        </IconSliderItem>
                    </StyledSlider>
                    <NextToSlider>
                        <P>
                            Das Loxone Smart Home stellt sein Wohnleben ideal auf die Bedürfnisse
                            seiner Bewohner ein und handelt im Einklang mit Jahreszeit, Witterung,
                            Präsenz und Lebensrhythmus der Menschen. Das Loxone Smart Home denkt wie
                            sein Besitzer – denkt mit, denkt vor, denkt selber.
                        </P>
                        <P>
                            Es nimmt Ihnen bis zu 50.000 potenzielle Handlungs- und Denkprozesse im
                            Jahr ab. Regelt für Sie automatisch Temperatur, Beschattung, Licht uvm.
                            – wenn Sie es denn so wollen.
                        </P>
                    </NextToSlider>
                </Container>
            </Section>
            <ContactTeaser />
        </Layout>
    );
};

LoxonePage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "loxone/stage-image.jpg" }) {
            ...StageFluid
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "loxone/contentimage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 585, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default LoxonePage;
